import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  Span,
} from "./styles";
import { Col, Drawer, Row } from "antd";

import { Button } from "../../common/Button";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { TFunction } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useState } from "react";

interface HeaderProps {
  t?: TFunction;
  showNav: boolean;
}
const Header = ({ t, showNav }: HeaderProps) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };
  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    const history = useHistory();
    return (
      <>
        {showNav ? (
          <>
            <CustomNavLinkSmall onClick={() => scrollTo("intro")}>
              <Span>{"About"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => scrollTo("about")}>
              <Span>{"Objectives"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => scrollTo("product")}>
              <Span>{"Goals"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => scrollTo("patners")}>
              <Span>{"Partners"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => {
              history.push("/competition")
              setVisibility(false);
              
              }}>
              <Span>{"Competition"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => {
              history.push("/event")
              setVisibility(false);
            }}>
              <Span>{"Event"}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall
              style={{ width: "180px" }}
              onClick={() => scrollTo("contact")}
            >
              <Span>
                <Button>{"Contact"}</Button>
              </Span>
            </CustomNavLinkSmall>
          </>
        ) : (
          <CustomNavLinkSmall onClick={() => {
            history.push("/")
            setVisibility(false);
          }}>
            <Span>{"Home"}</Span>
          </CustomNavLinkSmall>
        )}
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="venture.png" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
