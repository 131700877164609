import {
Extra,
FooterContainer,
FooterSection,
LogoContainer,
NavLink,
} from "./styles";

import Container from "../../common/Container";
import { Row } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import { TFunction } from "react-i18next";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t?: TFunction }) => {


  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>

      </FooterSection>
      <Extra>
        <Container border={false}>
          <Row
            justify="space-between"
            align="middle"
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="venture.png"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://twitter.com/_myyventureCap"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/myy-venture-capital"
                src="linkedin.svg"
              />
                            <SocialLink
                href="https://www.tiktok.com/@myyventurecapital?is_from_webapp=1&sender_device=pc"
                src="tiktok.svg"
              />
                                        <SocialLink
                href="https://www.youtube.com/channel/UCpRDZJlwe1JkC8x0UwKvp7w"
                src="youtube.svg"
              />
                                                  <SocialLink
                href="https://www.instagram.com/myyventurecapital"
                src="instagram.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default Footer;
